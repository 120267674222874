import React, { useState, useEffect, useRef } from "react"
import Styles from "../styles/user_profile.module.scss";
import SEO from "../components/seo"
import Layout from "../components/Layout"
import NewsItem from "../components/newsItem";
import ActivityIndicator from "../components/atoms/ActivityIndicator";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import { getInitialStateFromSessionStorage } from "../utils/getInitialStateFromSessionStorage";
import { BACK_BUTTON_STR } from "../constant/const";
import { NewsType } from "../types/models/News";
import client from "../apis/client";
import axios from "axios"


const NewsListPage = () => {

    const headerOption = {
        headerTitle: "運営からのお知らせ",
        leftIcon: BACK_BUTTON_STR,
        backButton: true,
    }

    const [ newsList, setNewsList ] = useState<NewsType[]>( getInitialStateFromSessionStorage( "newsList", [] ) )
    const [ nextNewsURL, setNextNewsURL ] = useState( getInitialStateFromSessionStorage( "nextNewsURL", "" ) )
    const [ fetchAtLeastOnce, setFetchAtLeastOnce ] = useState( false )
    const [ goingBack, setGoingBack ] = useState( false )
    const [ refreshing, setRefreshing ] = useState( false )

    const newsListRef = useRef( newsList )
    const nextNewsURLRef = useRef( nextNewsURL )

    useEffect( () => {
        initialFetchNewsList()
        setFetchAtLeastOnce( true )
    }, [] )

    useEffect( () => {
        newsListRef.current = newsList
    }, [ newsList ] )

    useEffect( () => {
        nextNewsURLRef.current = nextNewsURL
    }, [ nextNewsURL ] )


    const initialFetchNewsList = async () => {
        if ( newsList.length !== 0 ) {
            setFetchAtLeastOnce( true )
            return
        }
        await client.get( `/api/news/` )
            .then( res => {
                setNewsList( res.data.results )
                setNextNewsURL( res.data.next )
            } )
            .catch( error => console.log( error ) );
    }


    const goBack = async () => {
        if ( !nextNewsURLRef.current ) return
        setGoingBack( true )
        await client.get( nextNewsURLRef.current )
            .then( response => {
                const additional_news = response.data.results
                let newNewsList = newsListRef.current.concat( additional_news )
                setNewsList( newNewsList.slice() )
                setNextNewsURL( response.data.next )
            } ).catch( error => {
                console.log( { error } )
            } );

        setTimeout( () => setGoingBack( false ), 500 )

    }

    const refresh = () => {

    }

    const infiniteScroll = useInfiniteScroll( {
        container: typeof document !== 'undefined' ? document : null,
        goBack,
        refresh,
        goingBack,
        refreshing
    } )

    return (
        <Layout headerOption={ headerOption }>
            <>
                <SEO title="news" />
                <h1 style={ { padding: 12 } }>運営からのお知らせ一覧</h1>
                <div
                    className={ Styles.container }
                    style={ { backgroundColor: "#fff" } }
                    { ...infiniteScroll }>
                    <ul className={ Styles.user_news_container } style={ { paddingLeft: 8 } }>
                        { fetchAtLeastOnce && newsList.length === 0 &&
                            <p style={ {
                                fontSize: 24,
                                color: "rgba(0,0,0,0.54)",
                                textAlign: "center",
                                width: "100%",
                                padding: "12px 24px",
                                lineHeight: 1.4
                            } }>まだ運営からのお知らせはありません</p>
                        }
                        { newsListRef.current.map( news =>
                            <li key={ news.pk } >
                                <NewsItem news={ news } />
                            </li>
                        ) }
                        { goingBack &&
                            <ActivityIndicator /> }
                    </ul>
                </div>
            </>
        </Layout>
    );
}

export default NewsListPage
